<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Paises</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Paises</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_pais_export"
                        v-if="$store.getters.can('admin.paises.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-paises"
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Pais
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th class="text-center">Nombre Corto</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in arrListasForm.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pais in paises.data" :key="pais.id">
                      <td>{{ pais.nombre }}</td>
                      <td>{{ pais.nom_corto }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            pais.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ pais.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-paises"
                            @click="abrirModal('Editar', pais.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- Funcionalidad oculta por solicitud del cliente 
                            los métodos no fueron quitados -->
                          <!-- <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(pais.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="paises.total">
                  <p>
                    Mostrando del <b>{{ paises.from }}</b> al
                    <b>{{ paises.to }}</b> de un total:
                    <b>{{ paises.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="paises"
                  @pagination-change-page="getIndex"
                  :limit="3"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-paises">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="nombre"
                placeholder="Nombre Pais"
                v-model="objPais.nombre"
                :class="$v.objPais.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                @blur="validaPais"
              />
              <div class="error" v-if="!$v.objPais.nombre.required">
                Ingrese un nombre
              </div>
            </div>
            <div class="form-group">
              <label for="nom_corto">Nombre Corto</label>
              <input
                type="text"
                maxlength="3"
                class="form-control form-control-sm"
                id="nom_corto"
                placeholder="Nombre Corto del Pais"
                v-model="objPais.nom_corto"
                :class="
                  $v.objPais.nom_corto.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
              <div class="error" v-if="!$v.objPais.nom_corto.required">
                Ingrese un nombre corto, ISO3 (3 caracteres).
              </div>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="objPais.estado"
                :class="$v.objPais.estado.$invalid ? 'is-invalid' : 'is-valid'"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in arrListasForm.estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objPais.estado.required">
                Seleccione un estado
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.objPais.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <PaisExport ref="PaisExport" />
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  between,
} from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import PaisExport from "./PaisExport";

export default {
  name: "PaisIndex",
  components: {
    pagination,
    PaisExport,
  },
  data() {
    return {
      paises: {},
      objPais: {},
      filtros: {
        nombre: null,
        estado: null,
      },
      arrListasForm: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations: {
    objPais: {
      nombre: {
        required,
      },
      nom_corto: {
        required,
        maxLength: maxLength(3),
        minLength: minLength(3),
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.getEstados();
      axios
        .get("/api/admin/paises?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.paises = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.arrListasForm.estados = response.data;
      });
    },

    abrirModal(accion, idPais) {
      this.getEstados();
      this.modal.accion = accion;
      this.modal.title = accion + " Pais";
      if (accion == "Editar") {
        this.show(idPais);
      } else {
        this.objPais = {};
      }
    },

    validaPais() {
      let params = {
        nombre: this.objPais.nombre,
      };
      axios
        .get("/api/admin/paises/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.objPais.nombre = null;
            this.$swal({
              icon: "error",
              title: "El pais digitado ya está creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    save() {
      if (!this.$v.objPais.$invalid) {
        axios
          .post("/api/admin/paises", this.objPais)
          .then((response) => {
            this.$refs.closeModal.click();
            this.objPais = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idPais) {
      axios.get("/api/admin/paises/show/" + idPais).then((response) => {
        this.objPais = response.data;
      });
    },

    destroy(idPais) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/paises/" + idPais).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>